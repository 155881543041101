.btn-confirm {

  padding: 20px 0px;
  border: none;
  width: 335px;
  height: 60px;
  color: white;
  background: #009fe3;
  border-radius: 8px;
}

.btn-confirm-disable {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  border: none;
  width: 335px;
  height: 60px;
  color: white;
  background: #838383;
  border-radius: 8px;
}
