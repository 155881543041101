
.card h1 {
    color: #88B04B;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-weight: 900;
    font-size: 40px;
    margin-bottom: 10px;
}
.card p {
    color: #404F5E;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-size:20px;
    margin: 0;
}
.btn-custom:disabled{
    opacity: 0.35!important;
}
.myCustomButtonHeader{
    margin: 0!important;
    padding: 0!important;
    color: white!important;
    font-weight: bold!important;
}
.card i {
    color: #9ABC66;
    font-size: 100px;
    line-height: 200px;
    margin-left:-15px;
}
.card {
    text-align: center;
    background: transparent;
    padding: 60px;
    border: none;
    display: inline-block;
    margin: 0 auto;
    width: 97%;
    padding: 20px;
}

.displayForm{
    display: flex;
    width: 97%;
    padding: 20px;
    justify-content: space-around;
}
.myForm{
    width: 35%;
}
.myButtonUpload{
    height: 56px!important;
    background-color: transparent!important;
    box-shadow: none!important;
    border: 1px solid rgb(118 118 118 / 42%)!important;
}
.myErrorInfo{
    padding: 16px 24px;
    background: #f75858;
    color: white;
    font-weight: 800;
}
form label{
    padding-bottom: 10px;
    font-weight: bold;
}
